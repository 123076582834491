<template>
    <section class="validation">
        <div>
            <div class="page-header">
                <h3 class="page-title">
                    Send Promocodes To All Free Users
                </h3>
                <div class="button-group">
                    <!-- <button type="button" class="btn btn-info" @click="goBack()">
                        <i class="mdi mdi-keyboard-backspace"></i> Back</button> -->
                </div>
            </div>


            <div class="row">
                <div class="col-lg-7 m-auto">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h4 class="card-title mb-4">Edit Audio Category</h4> -->
                            <b-form @submit.stop.prevent="onSubmit">
                                <!-- <b-form-group id="example-input-group-1" label="Category Name"
                                    label-for="example-input-1">
                                    <b-form-input id="example-input-1" name="example-input-1"
                                        v-model="$v.form.name.$model"
                                        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                        aria-describedby="input-1-live-feedback"></b-form-input>
                                </b-form-group> -->

                                <div class="container-fluid my-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Select Promocode"
                                                label-for="example-input-1">
                                                <b-form-select v-model="$v.form.promocode_id.$model" class="mb-3"
                                                    :state="$v.form.promocode_id.$dirty ? !$v.form.promocode_id.$error : null">
                                                    <b-form-select-option disabled selected :value="null">Please select
                                                        promocode</b-form-select-option>
                                                    <b-form-select-option v-for="promo in promocodes" :value="promo.id">{{
                                                        promo.code }}</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>

                                        <!-- <div class="col-md-12">
                                            <b-form-group id="example-input-group-2" label="Select Users Platform"
                                                label-for="example-input-2">
                                                <b-form-select v-model="$v.form.platform.$model" class="mb-3"
                                                    :state="$v.form.platform.$dirty ? !$v.form.platform.$error : null">
                                                    <b-form-select-option :value="null">Please select platform</b-form-select-option>
                                                    <b-form-select-option value="web">Web</b-form-select-option>
                                                    <b-form-select-option value="android">Android</b-form-select-option>
                                                    <b-form-select-option value="ios">IOS</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div> -->

                                        <!-- <div class="col-md-12">
                                            <b-form-group id="example-input-group-3" label="Select Users Package"
                                                label-for="example-input-3">
                                                <b-form-select v-model="$v.form.package.$model" class="mb-3"
                                                    :state="$v.form.package.$dirty ? !$v.form.package.$error : null">
                                                    <b-form-select-option :value="null">Please select Package</b-form-select-option>
                                                    <b-form-select-option value="free">Free</b-form-select-option>
                                                    <b-form-select-option value="monthly">Monthly</b-form-select-option>
                                                    <b-form-select-option value="yearly">Yearly</b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div> -->
                                    </div>



                                    <div class="text-right mt-3">
                                        <b-button :disabled='is_loading' type="submit" variant="success"
                                            class="btn believe-btn btn-block">
                                            <i
                                                :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                            Submit
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                            <vue-snotify></vue-snotify>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioCategoryAddModal',
    mixins: [validationMixin],
    data() {
        return {
            msg: "",
            is_loading: false,
            form: {
                promocode_id: "",
                package: "",
                platform: "",
            },
            promocodes: [],
        }
    },
    components: {
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            promocode_id: {
                required
            },
            // package: {
            //     required
            // },
            // platform: {
            //     required
            // },
        }
    },

    mounted() {
        this.items();
    },


    methods: {


        async items() {
            let result = await api.get("/admin/all-promo");
            if (result.status == 200) {
                this.promocodes = result.data.all_promo
                this.promocodes = this.promocodes.filter((arr) => arr.status == "Active")
                console.log(this.promocodes);
            }
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            this.user_id = data?.id;
        },





        async onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return false;
            }

            try {
                this.is_loading = true;
                // const obj = this.$store.getters['getEditFormData'];
                let endpoint = "/admin/generate-promolink-free-users";
                // generate-promo-code-link-bulk";
                const formData = new FormData();
                formData.append("promocode_id", this.form.promocode_id);
                // formData.append("package", "free");
                // formData.append("platform", this.form.platform);

                let result = await api.post(endpoint, formData);
                // if (result.status == 200 || result.status == 422) {
                    // const msg = await result.data.message;
                    // this.$store.dispatch('setMessage', { msg });
                    // await this.$store.dispatch(`${type}/all`);
                    // let prev_url = `/events/list/`;
                    // this.$router.push({ path: prev_url });
                    this.$swal('Success!', 'Discount link has been sent successfully', 'success')
                    this.is_loading = false;
                // }
            } catch (error) {
                this.$swal('Success!', 'Discount link has been sent successfully', 'success')
                this.is_loading = false;
                // this.progress = 0;
                // let err = error.response.data.message;
                // this.$store.dispatch('setMessage', { err });
            }
        },

    },




}
</script>

<style>
footer.b-time-footer {
    padding: 0px !important;
    background-color: #fff !important;
}
</style>